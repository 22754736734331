import React from 'react'

import SEO from '~/components/seo'
import ProductGrid from '~/components/ProductGrid'
import { graphql, StaticQuery } from 'gatsby'
import Image from 'gatsby-image'

import FunkyShapeCom from '~/components/FunkyShape'
import { Container, GridItem, TwoColumnGrid } from '../utils/styles'

const IndexPage = ({ data }) => {
  const [tag, setTag] = React.useState('London')

  return (
    <>
      <SEO title="Lucie Conoley | The Mips Scribbles"  description="Directly printed from the brain of Lucie Conoley"  keywords={[`Mips`, `maps`, `prints`, `scribbles`]} />
      <TwoColumnGrid>
        <GridItem>
          <FunkyShapeCom fill={'#DC85B7'}>
            <h2 className="is-1 title">
              Drawing, keeping my little world ticking round!
            </h2>
          </FunkyShapeCom>
          <ProductGrid tag={'sqribble'} maxColumns={2} />
        </GridItem>
        <GridItem>
          {' '}
          <Image
            fluid={data.worldImage.childImageSharp.fluid}
            alt="Lucie Conoley"
          />
        </GridItem>
      </TwoColumnGrid>
      <TwoColumnGrid>
        <GridItem></GridItem>
        <GridItem></GridItem>
      </TwoColumnGrid>
    </>
  )
}

export default function Header(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          worldImage: file(relativePath: { eq: "the-world.png" }) {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      `}
      render={data => <IndexPage data={data} {...props} />}
    />
  )
}
